<template>
  <div>
    <b-row class="align-items-center">
      <b-col md="4">
        <b-form-group
          label="Fee Cycle"
          invalid-feedback="Fee Cycle is required."
          ref="filter"
        >
          <v-select
            ref="filter"
            multiple
            :closeOnSelect="false"
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="['All', ...filterOptions]"
            :clearable="false"
            label="text"
            placeholder="Select"
            @input="setCycle()"
          />
        </b-form-group>
      </b-col>
      <b-button
        class="ml-1"
        :disabled="dataLoading"
        variant="primary"
        @click="loadData()"
      >
        Show
      </b-button>
    </b-row>
    <div v-if="dataLoading">
      <b-row align-h="center">
        <b-spinner
          class="m-5"
          style="width: 4rem; height: 4rem"
          type="grow"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </b-row>
    </div>
    <template v-else>
      <b-card class="">
        <b-row class="">
          <b-col class="mb-50">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-primary">
                  <feather-icon size="24" icon="UsersIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5 class="font-weight-bolder mb-0">Total Students</h5>
                <b-card-text class="font-small-3 mb-0">
                  {{ statsData.totalStudents }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col class="mb-50">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-success">
                  <feather-icon size="24" icon="TrendingUpIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5 class="font-weight-bolder mb-0">Total Fees</h5>
                <b-card-text class="font-small-3 mb-0">
                  {{ statsData.totalFee }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col class="mb-50">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-danger">
                  <feather-icon size="24" icon="FileTextIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5 class="font-weight-bolder mb-0">Exempt Amount</h5>
                <b-card-text class="font-small-3 mb-0">
                  {{ statsData.exemptAmount }} ({{ statsData.exemptCount }})
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col class="mb-50">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-info">
                  <feather-icon size="24" icon="TagIcon" />
                  <!-- <span style="font-size: 20px">Rs</span> -->
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5 class="font-weight-bolder mb-0">Discount Amount</h5>
                <b-card-text class="font-small-3 mb-0">
                  {{ statsData.discountAmount }} ({{ statsData.discountCount }})
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col class="mb-50">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-warning">
                  <feather-icon size="24" icon="ClipboardIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5 class="font-weight-bolder mb-0">Net Fee</h5>
                <b-card-text class="font-small-3 mb-0">
                  {{ statsData.netFee }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="mb-50">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-info">
                  <feather-icon size="24" icon="GridIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5 class="font-weight-bolder mb-0">Net Collection</h5>
                <b-card-text class="font-small-3 mb-0">
                  {{ statsData.netCollectionAmount }} ({{
                    statsData.netCollectionCount
                  }})
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col class="mb-50">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-primary">
                  <feather-icon size="24" icon="FileTextIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5 class="font-weight-bolder mb-0">Total Outstanding</h5>
                <b-card-text class="font-small-3 mb-0">
                  {{ statsData.totalOutstanding }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col class="mb-50">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-success">
                  <feather-icon size="24" icon="DatabaseIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5 class="font-weight-bolder mb-0">Today's Collection</h5>
                <b-card-text class="font-small-3 mb-0">
                  {{ statsData.todayCollectionAmount }} ({{
                    statsData.todayCollectionCount
                  }})
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col class="mb-50">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-danger">
                  <feather-icon size="24" icon="TrashIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5 class="font-weight-bolder mb-0">Deleted Fee</h5>
                <b-card-text class="font-small-3 mb-0">
                  {{ statsData.deleteFeeAmount }} ({{
                    statsData.deletedFeeCount
                  }})
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col class="mb-50">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-warning">
                  <feather-icon size="24" icon="InfoIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5 class="font-weight-bolder mb-0">Bad Debt</h5>
                <b-card-text class="font-small-3 mb-0">
                  {{ statsData.badDebtAmount }} ({{ statsData.badDebtCount }})
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col xl="9" lg="8">
          <b-card no-body>
            <b-card-header class="p-1">
              <h4 class="mb-0">Class wise</h4>
            </b-card-header>
            <b-table
              class=""
              show-empty
              ref="departmentTable"
              :items="departData"
              :fields="departFields"
              responsive
              hover
            >
              <template #table-busy>
                <div
                  class="d-flex justify-content-center mb-3"
                  style="margin-top: 50px"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>

              <template #cell(class)="data">
                <b-badge variant="light-primary">
                  {{ data.item.class }}
                </b-badge>
              </template>
              <template #cell(totalStudents)="data">
                <b-badge variant="light-primary">
                  {{ data.item.totalStudents }}
                </b-badge>
              </template>
              <template #cell(free)="data">
                <b-badge variant="light-primary">
                  {{ data.item.free }}
                </b-badge>
              </template>
              <template #cell(full)="data">
                <b-badge variant="light-primary">
                  {{ data.item.full }}
                </b-badge>
              </template>
              <template #cell(receivable)="data">
                <b-badge variant="light-primary">
                  {{ data.item.receivable }}
                </b-badge>
              </template>
              <template #cell(received)="data">
                <b-badge variant="light-primary">
                  {{ data.item.received }}
                </b-badge>
              </template>
              <template #cell(discount)="data">
                <b-badge variant="light-primary">
                  {{ data.item.discount }}
                </b-badge>
              </template>
              <template #cell(badDebts)="data">
                <b-badge variant="light-primary">
                  {{ data.item.badDebts }}
                </b-badge>
              </template>
              <template #cell(balance)="data">
                <b-badge variant="light-danger">
                  {{ data.item.balance }}
                </b-badge>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col xl="3" lg="4">
          <b-card>
            <vue-apex-charts
              type="radialBar"
              height="270"
              :options="supportTrackerRadialBar.chartOptions"
              :series="[statsData.currentRecovery]"
            />
          </b-card>
          <b-card no-body v-if="false">
            <b-card-header class="p-1">
              <h4 class="mb-0">Group wise</h4>
            </b-card-header>
            <b-table
              class=""
              show-empty
              ref="groupTable"
              :items="GroupData"
              :fields="GroupFields"
              responsive
              hover
              small
            >
              <template #table-busy>
                <div
                  class="d-flex justify-content-center mb-3"
                  style="margin-top: 50px"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>

              <template #cell(feetype)="data">
                {{ data.item.feetype }}
              </template>
              <template #cell(total)="data">
                {{ data.item.total }}
              </template>
              <template #cell(discount)="data">
                {{ data.item.discount }}
              </template>
              <template #cell(netFee)="data">
                {{ data.item.netFee }}
              </template>
              <template #cell(paid)="data">
                <b-badge variant="light-primary">
                  {{ data.item.paid }}
                </b-badge>
              </template>
              <template #cell(unpaid)="data">
                <b-badge variant="light-danger">
                  {{ data.item.unpaid }}
                </b-badge>
              </template>
              <template #cell(recovery)="data">
                <b-badge variant="light-primary">
                  {{ data.item.recovery }}
                </b-badge>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col md="12">
          <b-card no-body>
            <b-card-header class="p-1">
              <h4 class="mb-0">Recovery Fee cycle wise</h4>
            </b-card-header>
            <b-card-body>
              <chartjs-component-bar-chart
                :height="400"
                :data="barChartData"
                :options="barChartOptions"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BSpinner,
  BBadge,
  BFormInput,
  BFormGroup,
  BForm,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import VueApexCharts from "vue-apexcharts";
import ChartjsComponentBarChart from "@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentBarChart.vue";
import { $themeColors } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";

const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BSpinner,
    BBadge,
    BFormInput,
    BFormGroup,
    BForm,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    vSelect,
    flatPickr,
    VueApexCharts,
    ChartjsComponentBarChart,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      dataLoading: false,
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "65%",
              },
              track: {
                // background: "#fff",
                strokeWidth: "100%",
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: "#5e5873",
                  fontSize: "1rem",
                },
                value: {
                  offsetY: 15,
                  color: "#5e5873",
                  fontSize: "1.714rem",
                  formatter: function (val) {
                    return val.toFixed(2) + "%";
                  },
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            // dashArray: 8,
            lineCap: "round",
          },
          labels: ["Current Recovery"],
        },
      },
      barChartOptions: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: "bottom",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 10000,
                min: 0,
                max: 100000,
                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },
      barChartData: {
        labels: ["Dec 2023", "Apr 2023", "Apr 2024"],
        datasets: [
          {
            data: [40000, 25000, 95000],
            backgroundColor: $themeColors.primary,
            borderColor: "transparent",
          },
          // {
          //   data: [40000, 25000, 95000],
          //   backgroundColor: $themeColors.success,
          //   borderColor: "transparent",
          // },
        ],
      },
      filterOptions: ["All"],
      selected: ["All"],
      departFields: [
        { label: "class", key: "class" },
        { label: "total Students", key: "totalStudents" },
        { label: "free", key: "free" },
        { label: "full", key: "full" },
        { label: "receivable", key: "receivable" },
        { label: "received", key: "received" },
        { label: "discount", key: "discount" },
        { label: "bad debts", key: "badDebts" },
        { label: "balance", key: "balance" },
      ],
      departData: [
        {
          class: "I - Boys",
          totalStudents: 20,
          free: 0,
          full: 0,
          discount: 0,
          receivable: 89500,
          received: 73000,
          badDebts: 3500,
          balance: 13000,
        },
        {
          class: "I - Girls",
          totalStudents: 23,
          free: 0,
          full: 0,
          discount: 0,
          receivable: 98000,
          received: 65000,
          badDebts: 4500,
          balance: 28500,
        },
        {
          class: "VIII - Boys",
          totalStudents: 25,
          free: 2,
          full: 0,
          discount: 0,
          receivable: 97000,
          received: 64000,
          badDebts: 0,
          balance: 32500,
        },
        {
          class: "VIII - Girls",
          totalStudents: 22,
          free: 0,
          full: 0,
          discount: 0,
          receivable: 91500,
          received: 78000,
          badDebts: 4000,
          balance: 9500,
        },
        {
          class: "O Levels - Boys",
          totalStudents: 19,
          free: 0,
          full: 0,
          discount: 0,
          receivable: 79000,
          received: 59500,
          badDebts: 0,
          balance: 19500,
        },
        {
          class: "O Levels - Girls",
          totalStudents: 20,
          free: 0,
          full: 0,
          discount: 0,
          receivable: 89500,
          received: 73000,
          badDebts: 3500,
          balance: 13000,
        },
        {
          class: "REC - Girls",
          totalStudents: 30,
          free: 0,
          full: 0,
          discount: 0,
          receivable: 121500,
          received: 72500,
          badDebts: 4000,
          balance: 45000,
        },
        {
          class: "O Levels III - Boys",
          totalStudents: 28,
          free: 0,
          full: 4,
          discount: 0,
          receivable: 105000,
          received: 77000,
          badDebts: 0,
          balance: 28000,
        },
        {
          class: "O Levels III - Girls",
          totalStudents: 23,
          free: 2,
          full: 9,
          discount: 0,
          receivable: 101500,
          received: 78250,
          badDebts: 0,
          balance: 23250,
        },
      ],
      GroupFields: [
        { label: "fee type", key: "feetype" },
        { label: "total", key: "total" },
        { label: "discount", key: "discount" },
        { label: "net fee", key: "netFee" },
        { label: "paid", key: "paid" },
        { label: "unpaid", key: "unpaid" },
        { label: "recovery", key: "recovery" },
      ],
      GroupData: [],
      statsData: {
        totalStudents: 0,
        totalFee: 0,
        exemptCount: 0,
        exemptAmount: 0,
        discountCount: 0,
        discountAmount: 0,
        netFee: 0,
        netCollectionCount: 0,
        netCollectionAmount: 0,
        totalOutstanding: 0,
        todayCollectionCount: 0,
        todayCollectionAmount: 0,
        deletedFeeCount: 0,
        deleteFeeAmount: 0,
        badDebtCount: 0,
        badDebtAmount: 0,
        currentRecovery: 0,
      },
    };
  },
  created() {
    this.loadData();
    this.loadCycle();
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      put: "put",
      delete: "delete",
    }),

    async loadData() {
      if (this.selected.length > 0) {
        this.dataLoading = true;
        var obj = {
          url:
            this.$store.state.domain +
            "Fees/FeeDashboard?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          token: this.$store.state.userData.token,
          body: this.selected,
          subjects: true,
        };
        this.statsData = await this.post(obj);
        //   console.log(this.statsData);
        this.dataLoading = false;
      }
    },
    async loadCycle() {
      var obj = {
        url:
          this.$store.state.domain +
          "Fees/FeeCycle?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.filterOptions = await this.get(obj);
      //   console.log(this.filterOptions);
    },
    setCycle() {
      if (this.selected.at(-1) == "All") {
        this.selected = ["All"];
      } else {
        this.selected = this.selected.filter((el) => el != "All");
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style></style>
